import http from "../utils/http";
import { stringify } from 'qs';

export function zdeliveryList(params) {
    return http.get("/zdelivery/zdeliveryList?" + stringify(params));
}

export function zdeliveryInfo(params) {
    return http.get("/zdelivery/zdeliveryInfo?" + stringify(params));
}

export function batchOperation(params) {
    return http.post("/zdelivery/batchOperation?" + stringify(params));
}
