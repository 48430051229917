import http from "../utils/http";
import { stringify } from 'qs';

export function siteList(params) {
    return http.get("/site/siteList?" + stringify(params));
}

export function siteInfo(params) {
    return http.get("/site/siteInfo?" + stringify(params));
}

export function addSite(params) {
    return http.post("/site/addSite?" + stringify(params));
}

export function updateSiteStatus(params) {
    return http.get("/site/updateSiteStatus?" + stringify(params));
}

export function siteInfoList() {
    return http.get("/site/siteInfoList");
}

export function regInfoList(params) {
    return http.get("/reg/list?"+ stringify(params));
}
