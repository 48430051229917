<template>
  <div class="RegistrationReview">
    <div class="content">
      <el-form label-width="80px">
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form-item label="骑手名字">
              <el-input placeholder="请输入骑手名字" v-model.trim="searchForm.name" :clearable="true"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6">
          <el-form label-width="90px">
            <el-form-item label="门店名">
              <el-select v-model="searchForm.siteName" placeholder="请选择门店" style="width: 100%" :clearable="true">
                  <el-option
                    v-for="item in siteIdList"
                    :key="item.value"
                    :label="item.label" 
                    :value="item.label">
                  </el-option>
                </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="6">
            <el-form label-width="90px">
            <el-form-item label="工作类型">
              <el-select v-model.trim="searchForm.jobType" placeholder="请选择">
                <el-option v-for="item in jobTypeoption" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            </el-form>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="6" style="padding-left: 10px">
            <el-button type="primary" @click="search()">查询</el-button>
            <el-button type="primary" @click="reset()">重置</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-button type="primary" @click="batchOperationHandler('2')">审核通过</el-button>
            <el-button type="danger" @click="batchOperationHandler('3')">审核失败</el-button>
          </el-col>
        </el-row>
      </el-form>
      <div class="tableBox">
        <el-table
          :data="tableData"
          v-loading="tableLoading"
          max-height="650"
          border
          style="width: 100%"
          @selection-change="handleSelectionChange">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="createAt"
            label="创建时间">
          </el-table-column>
          <el-table-column
            prop="name"
            label="骑手姓名">
          </el-table-column>
          <el-table-column
            prop="phone"
            label="联系方式">
          </el-table-column>
          <el-table-column
            prop="address"
            label="所属城市">
          </el-table-column>
          <el-table-column
            prop="siteName"
            label="所属门店">
          </el-table-column>
          <el-table-column
            prop="areaName"
            label="区域主管">
          </el-table-column>
          <el-table-column
            prop="jobType"
            label="工作类型">
            <template slot-scope="scope">
              <span>{{formatType(scope.row.jobType)}}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="certification"
            label="是否认证">
            <template slot-scope="scope">
              <el-tag
                :type="scope.row.certification === 1 ? 'danger' : 'primary'"
                disable-transitions>{{scope.row.certification === 1 ? '未认证' : '已认证'}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
            label="操作">
            <template slot-scope="scope">
                <el-button
                @click.native.prevent="viewRow(scope.$index, tableData)"
                type="primary">
                更多信息
                </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="paginationBox">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          background
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
      <el-dialog
        title="更多信息"
        :visible.sync="visible"
        :before-close="editBeforeClose"
        width="60%">
        <el-form
          ref="form"
          :model="form">
          <el-row>
            <el-col :span="18">
              <el-form-item label="银行卡号" label-width="120px">
                <el-input v-model="form.delivery.bankCard"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="开户银行" label-width="120px">
                <el-input v-model="form.delivery.openingBank"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="银行预留手机号" label-width="120px">
                <el-input v-model="form.delivery.bankPhone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div>身份证照片</div>
                <el-table
                :data="form.idcardzhengList"
                border
                style="width: 100%">
                <el-table-column
                  prop="idcard_image"
                  label="身份证正面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="idcardList[0]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = idcardList[0]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="idcard_image"
                  label="身份证反面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="idcardList[1]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = idcardList[1]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="24">
              <div>健康证照片</div>
                <el-table
                :data="form.healthImageList"
                border
                style="width: 100%">
                <el-table-column
                  prop="idcard_image"
                  label="健康证正面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="healthList[0]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = healthList[0]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="idcard_image"
                  label="健康证反面照片">
                  <template>
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="healthList[1]" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = healthList[1]"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                </el-table>
            </el-col>
            <el-col :span="24">
              <div>保险信息</div>
              <el-table
                :data="form.insuranceList"
                border
                style="width: 100%">
                <el-table-column
                  prop="number"
                  label="保单号">
                </el-table-column>
                <el-table-column
                  prop="startTime"
                  label="保单生效时间">
                </el-table-column>
                <el-table-column
                  prop="endTime"
                  label="保单结束时间">
                </el-table-column>
                <el-table-column
                  prop="url"
                  label="保单">
                </el-table-column>
                <el-table-column
                  prop="createAt"
                  label="签署时间">
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="24">
              <div>骑手车辆信息</div>
              <el-table
                :data="form.vehicleList"
                border
                style="width: 100%">
                <el-table-column
                  prop="frameNumber"
                  label="车架号">
                </el-table-column>
                <el-table-column
                  prop="vehicleNumber"
                  label="车牌号">
                </el-table-column>
                <el-table-column
                  prop="createAt"
                  label="绑定时间">
                </el-table-column>
                <el-table-column
                  prop="updateAt"
                  label="解绑时间">
                </el-table-column>
                <el-table-column
                  prop="isImage"
                  label="正面照">
                  <template slot-scope="scope">
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="scope.row.isImage" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = scope.row.isImage"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="theImage"
                  label="反面照">
                  <template slot-scope="scope">
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="scope.row.theImage" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = scope.row.theImage"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="sideImage"
                  label="侧面照">
                  <template slot-scope="scope">
                      <div class="imageBox" style="width: 150px; height: 150px; display: inline-block;">
                        <img :src="scope.row.sideImage" style="width: 100%; height: 100%" />
                        <div class="maskBox">
                          <i
                            class="el-icon-zoom-in"
                            style="cursor: pointer;"
                            @click="imgVisible = true; dialogImageUrl = scope.row.sideImage"></i>
                        </div>
                      </div>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
            <el-col :span="24">
              <div>骑手分包协议</div>
              <el-table
                :data="form.deliveryContract"
                border
                style="width: 100%">
                <el-table-column
                  prop="startTime"
                  label="保单生效时间">
                </el-table-column>
                <el-table-column
                  prop="endTime"
                  label="保单结束时间">
                </el-table-column>
                <el-table-column
                  prop="url"
                  label="协议">
                </el-table-column>
                <el-table-column
                  prop="createAt"
                  label="签署时间">
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="editBeforeClose()">返 回</el-button>
          <!-- <el-button type="primary" @click="visible = true;">确 定</el-button> -->
        </span>
      </el-dialog>
      <el-dialog
        title="填写描述"
        :visible.sync="contentVisible"
        :before-close="beforeClose"
        width="30%">
        <el-form
          ref="formContent"
          :rules="formContentRules"
          :model="formContent">
          <el-form-item label="失败原因" label-width="80px" prop="content">
            <el-input v-model="formContent.content" placeholder="请填写失败原因"></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="beforeClose()">取 消</el-button>
          <el-button type="primary" @click="contentConfim();">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog :visible.sync="imgVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { zdeliveryList, zdeliveryInfo, batchOperation } from '../../../api/driverList';
import {siteInfoList,} from '../../../api/restaurant';
import {mapState} from 'vuex'

export default {
  data() {
    return {
      jobTypeoption:[
          {value:1,label:'兼职'},
          {value:2,label:'全职'}
      ],
      ids: '',
      idList: [],
      searchForm: {
        name: '',
        status: '1',
        jobType:'',
        siteName: ''
      },
      driverPhone: '',
      siteIdList:[],
      tableData: [],
      idcardList:[],
      healthList: [],
      imgVisible: false,
      dialogImageUrl: '',
      tableLoading: true,
      visible: false,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      form: {
        delivery: {
          bankCard: '',
          bankName: '',
          bankPhone: '',
          idcard_image: ''
        },
        insuranceList: [],
        vehicleList: [],
        deliveryContract: [],
        idcardzhengList: [],
        healthImageList: []
      },
      formContent: {
        content: ''
      },
      formContentRules: {
        content: [
          { required: true, message: '标签内容', trigger: 'blur' },
        ],
      },
      contentVisible: false,
    }
  },
  mounted() {
    /**
     * @author XU
     */
    this.zdeliveryList();
    this.siteInfoList();
  },
  computed:{
    ...mapState('login',['userinfo'])
  },
  methods: {
    async zdeliveryList() {
      try {
        let userId;
        if(this.userinfo.roleName.includes('管理员')){
          userId = 0;
        }
        else{
          userId = this.userinfo.id
        }
        const params = {
          page: this.currentPage,
          limit: this.pageSize,
          userId:userId,
          ...this.searchForm
        };
        this.tableLoading = true;
        this.tableData = [];
        let data = await zdeliveryList(params);
        if (data && data.zmemberList) {
          this.tableData = data.zmemberList;
          this.total = data.total;
        } else {
          this.total = 0;
        }
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
    async zdeliveryInfo() {
      try {
        const params = {
          phone:  this.driverPhone
        };
        let data = await zdeliveryInfo(params);
        this.form.delivery = data.delivery;
        this.form.insuranceList = data.insuranceList;
        this.form.vehicleList = data.vehicleList;
        this.form.deliveryContract = data.deliveryContract;
        if(data.delivery.idcardImage!="" && data.delivery.idcardImage!=null){
          this.idcardList = data.delivery.idcardImage.split(",")
          this.form.idcardzhengList= [1]
        }
        if(data.delivery.healthImage!="" && data.delivery.healthImage!=null){
          this.healthList = data.delivery.healthImage.split(",")
          this.form.healthImageList = [1]
        }
      } catch (error) {
        console.log("error:", error)
      }
    },
    async batchOperation(type) {
      try {
        const params = {
          status: type,
          deliveryId: this.idList.join(','),
          content: this.formContent.content
        };
        if (type === '2') {
          params.memberStatus = 2;
          //骑手审核通过后 content传空字符串
          params.content = "";
        }
        if (type === '3') {
          params.memberStatus = 1;
        }
        await batchOperation(params);
        this.zdeliveryList();
      } catch (error) {
        console.log("error:", error)
      }
      if (type === '3') {
        this.contentVisible = false;
        this.formContent.content = '';
      }
    },
    batchOperationHandler(type) {
      if (this.idList.length > 0) {
        console.log(this.idList)
        if (type === "2") {
          this.batchOperation('2');
        }
        if (type === "3") {
          this.contentVisible = true;
        }
      } else {
        this.$message({
          message: '请至少选择一条数据操作',
          type: 'warning'
        })
      }
    },
    contentConfim() {
      this.$refs['formContent'].validate((valid) => {
        if(valid){
          this.batchOperation('3');
        }else{
          console.log("error submit!")
          return false;
        }
      });
    },
      // 门店下拉
      async siteInfoList() {
      try {
        let data = await siteInfoList();
        const hubArr = [];
        data.forEach(item => {
          hubArr.push({
            value: item.id,
            label: item.name
          })
        })
        this.siteIdList = hubArr;
      } catch (error) {
        console.log("error:", error)
      }
      this.tableLoading = false;
    },
    beforeClose() {
      this.contentVisible = false;
      this.formContent.content = '';
    },
    editBeforeClose() {
        this.visible = false;
        this.$refs['form'].resetFields();
        this.$data.form=JSON.parse(JSON.stringify(this.$options.data().form)) 
    },
    handleSelectionChange(val) {
      let hubArray = [];
      val.forEach(item => {
        hubArray.push(item.id);
      });
      this.idList = hubArray;
    },
    viewRow(index, list) {
      this.driverPhone = list[index].phone;
      this.zdeliveryInfo();
      this.visible = true;
    },
    search() {
      this.currentPage = 1,
      this.pageSize = 10,
      this.zdeliveryList();
    },
    reset() {
      this.searchForm = {
        name: '',
        status: '1'
      };
      this.currentPage = 1,
      this.pageSize = 10,
      this.zdeliveryList();
    },
    handleSizeChange(size) {
      this.pageSize = size;
    },
    handleCurrentChange(current) {
      this.currentPage = current;
      this.zdeliveryList();
    },
    formatType(type) {
      let result = ""
      switch (type + "") {
        case "1":
          result = "兼职";
          break;
        case "2":
          result = "全职";
          break;
      }
      return result;
    },
  }
}
</script>

<style lang="scss" scoped>
.RegistrationReview {
    .content{
        .tableBox {
            padding-top: 20px;
        }
        .paginationBox {
            padding-top: 20px;
            text-align: center;
        }
    }
}
</style>